.heading-text {
    font-weight: bold;
}

.emphasize {
    font-family: nova-classic;
}

.icon {
    margin-right: 0.5em;
}

.x-small {
    font-size: 0.8em;
}

.small {
    font-size: 1em;
}

.medium {
    font-size: 1.2em;
}

.large {
    font-size: 1.6em;
}

.x-large {
    font-size: 2.3em;
}