.nav-bar {
    background: #0074c1;
    color: white;
    align-items: center;
    height: 70px;
    justify-content: space-between;
}

.nav-menu {
    margin: 1em;
}

.nav-item {
    padding: 0.5em 1em;
    border-right: 1px solid #0074c1;
    border-left: 1px solid #0074c1;
    background: #004d80;
    user-select: none;
    cursor: pointer;
}

.nav-item i {
    padding-right: 0.5em;
}

.nav-item:hover {
    border-right: 1px solid;
    border-left: 1px solid;
    box-shadow: 0 2px 8px 0 rgba(51, 51, 51, 0.3), 0 4px 8px 0 rgba(51, 51, 51, 0.3);
}

.nav-item:active {
    border-right: 1px solid;
    border-left: 1px solid;
    box-shadow: 0 2px 8px 0 rgba(51, 51, 51, 0.3), 0 4px 8px 0 rgba(51, 51, 51, 0.3);
    opacity: 0.75;
}

.nav-menu .fa, .nav-menu .far, .nav-menu .fas {
    color: white;
}

.logo {
    width: 16em;
    text-decoration: none;
    color: white;
}

.logo-slogan {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 0 1em;
}

.logo-slogan .site {
    font-weight: bold;
    font-size: 1.15em;
}

.logo-slogan .slogan {
    font-family: comforta-light;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.modal-content {
    height: auto;
    width: 500px;
    background: white;
    margin: auto;
    margin-top: 10em;
}

.portfolio-content {
    padding: 1em;
}

.portfolio-content .heading {
    justify-content: center;
}