.footer {
    background: #0074c1;
    color: white;
    padding: 0.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer .social-media a {
    color: white;
    padding: 0.25em;
}

.footer .social-media a:hover {
    color: rgba(255,255,255, 0.5);
}