body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #b3e0ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: bold;
  font-size: 1.1em;
  color: #595959;
}

.flex {
  display: flex;
}

.heading {
  color: #595959;
  align-items: center;
  font-size: 1em;
  margin: 0.3em 0;
}

.text {
  color: #262626;
}

.divider {
  border: 1px solid #e6e6e6;
  margin: 0.5em 1%;
}

.hidden {
  display: none;
}

/* Overrides */
.fa, .fas {
  color: #0074c1;
}

@media print {
    .modal-content {
      display: none;
    }

    .hide-from-print {
      display: none;
    }
}