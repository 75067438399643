.profile-photo {
    height: 240px;
    background: url(../static/me.jpg) no-repeat center;
    background-size: contain;
}

.caption {
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}

.caption-text {
    font-family: nova-classic;
    color: white;
    font-size: 1.4em;
    background: rgba(0,0,0,0.75);
    padding: 3px 15px;
    margin-bottom: 30px;
    border-radius: 16px;
    user-select: none;
    cursor: default;
}