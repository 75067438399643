@media screen {
    .print-only {
        display: none;
    }

    .content {
        flex-wrap: wrap;
    }
    
    .container {
        margin: 1em;
        background: #fff;
        padding: 1em;
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.2), 0 4px 6px 0 rgba(51, 51, 51, 0.19);
    }
    
    .side-container {
        flex: 1;
        width: 20em;
    }
    
    .measurables label{
        font-family: comforta-light;
    }
    
    .experiences {
        flex: 5;
    }
    
    .experiences .heading, .measurables .heading {
        margin-bottom: 0.8em;
    }
    
    .experience-item .heading {
        margin: 0.3em 0;
    }
    .experience-item .experience-summary {
        margin-top: 1em;
    }
    
    .bullets {
        margin-left: 0.5em;
    }
    
    .bullets .bullet {
        align-items: center;
        margin: 0.3em 0;
    }
    
    .bullets .bullet i {
        padding: 0.5em;
        margin-right: 0.5em;
    }
    
    .date-range {
        color: #0074c1;
        align-items: baseline;
    }
    
    .date-range i {
        margin-right: 0.5em;
    }
    
    .end-date.present {
        background: #0074c1;
        color: white;
        padding: 0.1em 0.3em;
        border-radius: 5px;
    }
    
    .signature {
        font-family: darcey-oliver;
        font-size: 3em;
        height: 63px;
        margin: 0;
        outline: 0;
    }
}

@media print {
    .print-only {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
    }

    .nav-bar, .profile-photo, .footer, .fa, .fas, .far, .measurables, .divider {
        display: none;
    }

    body {
        background: white;
        color: black;
        font-size: 1em;
    }

    p {
        margin: 0;
        margin-bottom: 5px;
    }
    .experiences .container {
        margin-bottom: 1em;
    }
    .side-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .content {
        display: block;
    }

    .fa, .fas, .heading {
        color: black;
    }

    .heading, .emphasize {
        font-family: lgc !important;
    }

    .bullet:before {
        content: '\25C8';
        margin-right: 5px;
        margin-left: 10px;
    }

    .experience-item {
        margin-bottom: 1em;
        page-break-inside: avoid;
    }

    .x-large {
        font-size: 1.3em !important;
    }

    .large {
        font-size: 1.2em !important;
    }

    .medium {
        font-size: 1.1em !important;
    }

    .small {
        font-size: 1em !important;
    }
}