.progress-bar-container {
    flex-direction: column;
    min-width: 3em;
}

.progress-bar-container label {
    padding: 0.5em 0;
}

.progress-bar {
    height: 20px;
    background: #e6e6e6;
    border-radius: 15px; 
}

.progress {
    height: 20px;
    background: #0074c1;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    color: white;
}