body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #b3e0ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  font-weight: bold;
  font-size: 1.1em;
  color: #595959;
}

.flex {
  display: flex;
}

.heading {
  color: #595959;
  align-items: center;
  font-size: 1em;
  margin: 0.3em 0;
}

.text {
  color: #262626;
}

.divider {
  border: 1px solid #e6e6e6;
  margin: 0.5em 1%;
}

.hidden {
  display: none;
}

/* Overrides */
.fa, .fas {
  color: #0074c1;
}

@media print {
    .modal-content {
      display: none;
    }

    .hide-from-print {
      display: none;
    }
}
.app {
    font-family: lgc;
    font-size: 1.1em;
}
@media screen {
    .print-only {
        display: none;
    }

    .content {
        flex-wrap: wrap;
    }
    
    .container {
        margin: 1em;
        background: #fff;
        padding: 1em;
        box-shadow: 0 3px 6px 0 rgba(51, 51, 51, 0.2), 0 4px 6px 0 rgba(51, 51, 51, 0.19);
    }
    
    .side-container {
        flex: 1 1;
        width: 20em;
    }
    
    .measurables label{
        font-family: comforta-light;
    }
    
    .experiences {
        flex: 5 1;
    }
    
    .experiences .heading, .measurables .heading {
        margin-bottom: 0.8em;
    }
    
    .experience-item .heading {
        margin: 0.3em 0;
    }
    .experience-item .experience-summary {
        margin-top: 1em;
    }
    
    .bullets {
        margin-left: 0.5em;
    }
    
    .bullets .bullet {
        align-items: center;
        margin: 0.3em 0;
    }
    
    .bullets .bullet i {
        padding: 0.5em;
        margin-right: 0.5em;
    }
    
    .date-range {
        color: #0074c1;
        align-items: baseline;
    }
    
    .date-range i {
        margin-right: 0.5em;
    }
    
    .end-date.present {
        background: #0074c1;
        color: white;
        padding: 0.1em 0.3em;
        border-radius: 5px;
    }
    
    .signature {
        font-family: darcey-oliver;
        font-size: 3em;
        height: 63px;
        margin: 0;
        outline: 0;
    }
}

@media print {
    .print-only {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
    }

    .nav-bar, .profile-photo, .footer, .fa, .fas, .far, .measurables, .divider {
        display: none;
    }

    body {
        background: white;
        color: black;
        font-size: 1em;
    }

    p {
        margin: 0;
        margin-bottom: 5px;
    }
    .experiences .container {
        margin-bottom: 1em;
    }
    .side-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .content {
        display: block;
    }

    .fa, .fas, .heading {
        color: black;
    }

    .heading, .emphasize {
        font-family: lgc !important;
    }

    .bullet:before {
        content: '\25C8';
        margin-right: 5px;
        margin-left: 10px;
    }

    .experience-item {
        margin-bottom: 1em;
        page-break-inside: avoid;
    }

    .x-large {
        font-size: 1.3em !important;
    }

    .large {
        font-size: 1.2em !important;
    }

    .medium {
        font-size: 1.1em !important;
    }

    .small {
        font-size: 1em !important;
    }
}
.footer {
    background: #0074c1;
    color: white;
    padding: 0.5em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer .social-media a {
    color: white;
    padding: 0.25em;
}

.footer .social-media a:hover {
    color: rgba(255,255,255, 0.5);
}
.heading-text {
    font-weight: bold;
}

.emphasize {
    font-family: nova-classic;
}

.icon {
    margin-right: 0.5em;
}

.x-small {
    font-size: 0.8em;
}

.small {
    font-size: 1em;
}

.medium {
    font-size: 1.2em;
}

.large {
    font-size: 1.6em;
}

.x-large {
    font-size: 2.3em;
}
.progress-bar-container {
    flex-direction: column;
    min-width: 3em;
}

.progress-bar-container label {
    padding: 0.5em 0;
}

.progress-bar {
    height: 20px;
    background: #e6e6e6;
    border-radius: 15px; 
}

.progress {
    height: 20px;
    background: #0074c1;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    color: white;
}
.nav-bar {
    background: #0074c1;
    color: white;
    align-items: center;
    height: 70px;
    justify-content: space-between;
}

.nav-menu {
    margin: 1em;
}

.nav-item {
    padding: 0.5em 1em;
    border-right: 1px solid #0074c1;
    border-left: 1px solid #0074c1;
    background: #004d80;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.nav-item i {
    padding-right: 0.5em;
}

.nav-item:hover {
    border-right: 1px solid;
    border-left: 1px solid;
    box-shadow: 0 2px 8px 0 rgba(51, 51, 51, 0.3), 0 4px 8px 0 rgba(51, 51, 51, 0.3);
}

.nav-item:active {
    border-right: 1px solid;
    border-left: 1px solid;
    box-shadow: 0 2px 8px 0 rgba(51, 51, 51, 0.3), 0 4px 8px 0 rgba(51, 51, 51, 0.3);
    opacity: 0.75;
}

.nav-menu .fa, .nav-menu .far, .nav-menu .fas {
    color: white;
}

.logo {
    width: 16em;
    text-decoration: none;
    color: white;
}

.logo-slogan {
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding: 0 1em;
}

.logo-slogan .site {
    font-weight: bold;
    font-size: 1.15em;
}

.logo-slogan .slogan {
    font-family: comforta-light;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.modal-content {
    height: auto;
    width: 500px;
    background: white;
    margin: auto;
    margin-top: 10em;
}

.portfolio-content {
    padding: 1em;
}

.portfolio-content .heading {
    justify-content: center;
}
.profile-photo {
    height: 240px;
    background: url(/static/media/me.523a6ca7.jpg) no-repeat center;
    background-size: contain;
}

.caption {
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}

.caption-text {
    font-family: nova-classic;
    color: white;
    font-size: 1.4em;
    background: rgba(0,0,0,0.75);
    padding: 3px 15px;
    margin-bottom: 30px;
    border-radius: 16px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: default;
}
